export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const getYoutubeId = (value) => {
  const SRC_REGEX = /src="(.*?)"/im;

  const url = SRC_REGEX.test(value) ? value.match(SRC_REGEX)[1] : value;

  const { searchParams, pathname } = new URL(url);

  return searchParams.get('v') || pathname.replace('/embed', '').replace('/', '');
};

export const nl2br = (str: string = '', isXhtml: boolean = false): string => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';

  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

export const filterByTagId = (items, tid: string, limit: number = 0) => {
  let filtered;
  if (tid === '') {
    filtered = items;
  } else {
    filtered = items.filter((item) => item.tags.id === tid);
  }

  return limit === 0 ? filtered : filtered.slice(0, limit);
};

export default isEmpty;
